<template>
  <div class="exchange-rate">
    <CCard>
      <CCardBody>
        <h6>{{$t("EXCHANGE_RATE_PAGE_TITLE_EXCHANGE_RATE")}}</h6>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <CForm @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol md="3">
                <ValidationProvider name="EXCHANGE_RATE_PAGE_FIELD_NAME_VALIDATE_CURRENCY" v-slot="{ errors }" rules>
                  <CSelect
                    :label="$t('EXCHANGE_RATE_PAGE_LABEL_SELECT_CURRENCY')"
                    :value.sync="valueOption"
                    :options="options"
                  />
                  <CRow>
                    <CCol :md="12">
                      <span class="error-msg mt-1">{{errors[0]}}</span>
                    </CCol>
                  </CRow>
                </ValidationProvider>
              </CCol>
              <CCol md="3">
                <ValidationProvider name="EXCHANGE_RATE_PAGE_FIELD_NAME_VALIDATE_NEW_RATE" v-slot="{ errors }" :rules="rules">
                  <label>{{$t('EXCHANGE_RATE_PAGE_LABEL_NEW_RATE')}}</label>
                  <currency-input
                    type="text"
                    v-model="usdRate"
                    ref="rate"
                    :precision="3"
                    class="form-control"
                  />
                  <span class="error-msg">{{errors[0]}}</span>
                </ValidationProvider>
              </CCol>
              <CCol md="3">
                <CButton
                  v-if="isAllowedUpdating"
                  color="primary"
                  class="btn btn-primary"
                  @click="onSubmit"
                >{{$t("EXCHANGE_RATE_PAGE_BUTTON_NEW_RATE")}}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
        <div class="history" v-if="isAllowedView">
          <h6>{{$t("EXCHANGE_RATE_PAGE_TITLE_HISTORY")}}</h6>
          <CRow>
            <CCol md="12">
              <ListHistoryFiatRate
                :isAllowedView="isAllowedView"
                :limit="limit"
                :activePage="activePage"
                :historyFiatRate="historyFiatRate"
                :total="total"
              />
            </CCol>
            <CCol md="12">
              <StakingPagination
                :limit="limit"
                :total="total"
                :activePage="activePage"
                @changePage="onChangePage"
                @changePageSize="onChangePageSize"
              />
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { endpoints, env } from '@/constants';
import responseHelper from '@/utils/response-helper';
import ListHistoryFiatRate from '@/components/ExchangeRate/ListHistoryFiatRate';
import StakingPagination from '@/components/StakingPagination';

export default {
  name: 'ExchangeRate',
  components: {
    ListHistoryFiatRate,
    StakingPagination,
  },
  data() {
    return {
      options: [
        {
          label: 'USD/JPY',
          value: 'USD/JPY',
        },
      ],
      valueOption: '',
      historyFiatRate: [],
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      putActivePage: 1,
      usdRate: null,
      isAllowedUpdating: false,
      isAllowedView: false,
      rules: {
        required: true,
        max_value: Number.MAX_SAFE_INTEGER,
        min_value: 1,
      },
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.getHistoryFiatRate();
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_FIAT_RATE') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'MEMBERSHIP_VIEW_FIAT_RATE') {
            this.isAllowedView = true;
          }
        });
      }
    },
    async getHistoryFiatRate() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getHistoryFiatRate, { params });
        this.historyFiatRate = result.data.items;
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_RATE_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }
        this.add();
        this.usdRate = '';
        this.$refs.form.reset();
      });
    },
    async add() {
      try {
        const data = {
          usd_rate_by_jpy: this.usdRate,
        };
        const result = await this.$http.put(endpoints.addFiatRate, data);
        await this.getHistoryFiatRate();
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EXCHANGE_RATE_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('EXCHANGE_RATE_PAGE_TEXT_NOTIFY_SUCCESS'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_RATE_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;
      this.getHistoryFiatRate();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;
      this.getHistoryFiatRate();
    },
  },
};
</script>
<style lang="scss">
.exchange-rate {
  padding: 0 15px;
  h6 {
    color: #657187;
    font-size: 18px;
    font-weight: 600;
  }
  form {
    margin: 30px 0;
    label {
      margin-bottom: 0;
      font-weight: 300;
    }
    button {
      margin-top: 20px;
      border-radius: 45px;
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      background: #3b4b7e !important;
      height: 37px;
      width: 100%;
      border: none;
      color: #fff;
      &:hover {
        background: #3b4b7e !important;
        color: #fff;
      }
    }
  }
  .error-msg {
    color: red;
  }
}
</style>
