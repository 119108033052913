var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.historyFiatRate,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","itemsPerPage":_vm.limit},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.createdAt) || '-'))])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.end_date) || '-'))])]}},{key:"currency_exchange",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.currency_exchange || '-'))])]}},{key:"rate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rate || '-'))])]}},{key:"usd",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(Number(item.rate * 100).toLocaleString())+" JPY")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }