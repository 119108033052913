<template>
  <div>
    <CCard>
      <CDataTable
        :items="historyFiatRate"
        :fields="fields"
        fixed
        hover
        striped
        bordered
        :itemsPerPage="limit"
      >
        <template #createdAt="{item}">
          <td>{{formatDate(item.createdAt) || '-'}}</td>
        </template>
        <template #end_date="{item}">
          <td>{{formatDate(item.end_date) || '-'}}</td>
        </template>
        <template #currency_exchange="{item}">
          <td>{{item.currency_exchange || '-'}}</td>
        </template>
        <template #rate="{item}">
          <td>{{item.rate || '-'}}</td>
        </template>
        <template #usd="{item}">
          <td>{{Number(item.rate * 100).toLocaleString()}} JPY</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Constants } from '@/constants';

export default {
  name: 'ListHistoryFiatRate',
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'createdAt',
          label: this.$t('LIST_HISTORY_FIAT_RATE_COMPONENT_LABEL_CREATE_AT'),
        },
        {
          key: 'end_date',
          label: this.$t('LIST_HISTORY_FIAT_RATE_COMPONENT_LABEL_END_DATE'),
        },
        {
          key: 'currency_exchange',
          label: this.$t('LIST_HISTORY_FIAT_RATE_COMPONENT_LABEL_CURRENCY_EXCHANGE'),
        },
        {
          key: 'rate',
          label: this.$t('LIST_HISTORY_FIAT_RATE_COMPONENT_LABEL_RATE'),
        },
        {
          key: 'usd',
          label: this.$t('LIST_HISTORY_FIAT_RATE_COMPONENT_LABEL_COLUMN_USD'),
        },
      ],
    };
  },
  props: {
    historyFiatRate: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    isAllowedView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        return this.$moment(date).format(Constants.dateFormat);
      }

      return null;
    },
  },
};
</script>
<style lang="scss">
</style>
